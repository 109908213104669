import React from 'react';
import { SectionHeader } from '../shared/section-header/SectionHeader';
import { ProjectTechnology } from '../../types/Project.interface';
import { Tag } from '../shared/tag/Tag';
import * as classes from './Technology.module.scss';

export interface TechnologyProps {
    libraries: string[];
    languages: ProjectTechnology[];
}

const Technology: React.FC<TechnologyProps> = ({
    libraries,
    languages
}) => {
    return (
        <section className={classes['technology']}>

            <SectionHeader 
                title="Technology"
                description="What techologies & libraries I've used."
            />

            <div className={classes['libraries']}>
                {
                    libraries.map((item) => 
                        <Tag key={item} label={item} />
                    )
                }
            </div>

            <div className={classes['languages']}>

                <div className={classes['chart']}>
                    { 
                        languages.map((item) =>
                            <div
                                key={item.name}
                                className={classes['chart__item']}
                                style={{
                                    background: item.color,
                                    width: `${item.percentage}%`
                                }}
                            />
                        )
                    }
                </div>

                <div className={classes['legend']}>
                    { 
                        languages.map((item) =>
                            <div key={item.name} className={classes['legend__item']}>
                                <div
                                    className={classes['legend__circle']} 
                                    style={{ background: item.color }}
                                />
                                <span className={classes['legend__label']}>
                                    { item.name }
                                </span>
                            </div>
                        )
                    }
                </div>

            </div>

        </section>
    );
}

export { Technology }