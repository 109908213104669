import React from 'react';
import { DefaultLayout } from '../../layouts/default';
import { useStaticQuery, graphql } from 'gatsby';
import { projects } from '../../data/projects';
import { Gallery, Hero, Technology, About } from '../../components/project';
import { Redirect } from '@reach/router';
import * as classes from './index.module.scss';
import '../../assets/scss/global.scss';
import { Helmet } from 'react-helmet';

export interface ProjectProps {
    pageContext: {
        ID: string;
    }
}

const Project: React.FC<ProjectProps> = ({
    pageContext: { ID }
}) => {
    // Find Project by Path
    const project = projects.find((x) => x.path === ID);

    if (!project?.name) {
        return (
            <Redirect noThrow to="/" />
        );
    }

    // Query Images
    const query = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        name
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    `);

    // Filter Out Gallery & Hero images
    const galleryImages = query.allFile.edges.filter((item) => item.node.name.includes(project.galleryPrefix));

    return (
        <DefaultLayout className={classes['Portfolio']}>

            <Helmet>
                <title>Bravecode | Project Details | { project.title }</title>
                <meta  name="description" content="Portfolio of Krzysztof Szymański known as bravecode. Explore Digital CV and detailed project descriptions. Let's work together!" />
            </Helmet>

            <main>

                <Hero 
                    title={project.title}
                    subtitle={project.type}
                    icon={project.icon}
                />

                <About 
                    duration={project.duration}
                    categories={project.categories}
                    description={project.description}
                    type={project.type}
                />

                <Gallery data={galleryImages} />

                <Technology 
                    libraries={project.technologies.libraries}
                    languages={project.technologies.languages}
                />

            </main>

        </DefaultLayout>
    );
}

export default Project;